import React from "react";
import { Box, Text } from "@chakra-ui/layout";

import Pinpoint from "./Pinpoint";
import Goal from "./Goal";

interface IProps {
  pinpoints: boolean;
  green: number;
  yellow?: number;
  red?: number;
  max: number;
  goal?: number;
  goalReached?: boolean;
}

function percentageCalc(value: number, max: number) {
  return value > 0 ? (value / max) * 100 + "%" : "0%";
}

const StackedBar: React.FC<IProps> = (props) => {
  const { pinpoints, green, yellow, red, max, goal, goalReached } = props;
  return (
    <React.Fragment>
      {goal && (
        <Box w="100%">
          <Goal value={goal} goalReached={goalReached} max={max} />
        </Box>
      )}
      <Box
        h="1rem"
        w="100%"
        bg="black.900"
        borderRadius="1rem"
        overflow="hidden"
      >
        {green && (
          <Box
            w={percentageCalc(green, max)}
            bg="green.light"
            h="100%"
            verticalAlign="top"
            display="inline-block"
            textAlign="right"
          >
            {goal && (
              <Text
                color="white.500"
                fontSize="small"
                paddingRight="0.2rem"
                lineHeight="1rem"
              >
                {green}
              </Text>
            )}
          </Box>
        )}
        {yellow && (
          <Box
            w={percentageCalc(yellow, max)}
            bg="yellow.dark"
            h="100%"
            verticalAlign="top"
            display="inline-block"
            textAlign="right"
          >
            <Text
              color="white.500"
              fontSize="small"
              paddingRight="0.2rem"
              lineHeight="1rem"
            >
              {yellow}
            </Text>
          </Box>
        )}
        {red && (
          <Box
            w={percentageCalc(red, max)}
            bg="primary.main"
            h="100%"
            verticalAlign="top"
            display="inline-block"
            textAlign="right"
          >
            <Text
              color="white.500"
              fontSize="small"
              paddingRight="0.2rem"
              lineHeight="1rem"
            >
              {red}
            </Text>
          </Box>
        )}
      </Box>
      {pinpoints && (
        <Box w="100%">
          <Pinpoint letter="C" credits={green} max={max} />
          <Pinpoint letter="S" credits={green} max={max} />
          <Pinpoint letter="G" credits={green} max={max} />
          <Pinpoint letter="P" credits={green} max={max} />
        </Box>
      )}
    </React.Fragment>
  );
};

export default StackedBar;
