import React from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";

import RowVisibleInfo from "./RowVisibleInfo";
import RowSchema from "./RowSchema";
import RowBody from "./RowBody";

interface IProps {
  credit: any;
}

const Row: React.FC<IProps> = (props) => {
  const { credit } = props;
  return (
    <AccordionItem>
      <AccordionButton padding="0.25rem 0">
        <RowSchema>
          <RowVisibleInfo credit={credit} />
        </RowSchema>
      </AccordionButton>
      <AccordionPanel padding="0.5rem 0">
        <RowBody credit={credit} />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Row;
