import { SimpleGrid } from "@chakra-ui/react";
import React from "react";

interface IProps {
  children: any;
  marginBottom?: string;
}

const RowSchema: React.FC<IProps> = (props) => {
  const { marginBottom } = props;
  return (
    <SimpleGrid
      columns={{ sm: 9, lg: 11 }}
      width="100%"
      marginBottom={marginBottom}
    >
      {props.children}
    </SimpleGrid>
  );
};

export default RowSchema;
