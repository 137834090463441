import React from "react";
import { Select } from "@chakra-ui/react";

import Label from "./Label";
import { ICustomFilters } from "../FilterGroup";

interface IProps {
  title: string;
  filterKey: string;
  optionsList: string[] | undefined;
  creditFilters: ICustomFilters;
  setCreditFilters: (filters: ICustomFilters) => void;
}

const Dropdown: React.FC<IProps> = (props) => {
  const { title, filterKey, optionsList, creditFilters, setCreditFilters } =
    props;
  return (
    <>
      <Label title={title} />
      <Select
        // placeholder={dropdownPlaceholder}
        bg="white"
        display="inline-block"
        w="100%"
        mt=".5rem"
        size="sm"
        value={(creditFilters as any)[filterKey as any]}
        onChange={(e) =>
          setCreditFilters({ ...creditFilters, [filterKey]: e.target.value })
        }
      >
        <option value="">All {title.toLowerCase()}</option>
        {!!optionsList?.length &&
          optionsList.map((option, index) => (
            <option value={option} key={`${option}_${index}`}>
              {option}
            </option>
          ))}
      </Select>
    </>
  );
};

export default Dropdown;
