import { Flex } from "@chakra-ui/layout";
import React, { useState } from "react";

import NavigationPane from "./NavigationPane";
import CardFilter from "./../core/Home/CardFilter";
import { useHistory } from "react-router";
interface IProps {
  children: any;
}

const Frame: React.FC<IProps> = (props) => {
  const history = useHistory();

  const [showFilter, setShowFilter] = useState(
    history.location.pathname === "/",
  );
  history.listen((ls) => setShowFilter(ls.pathname === "/"));

  return (
    <Flex padding="0rem 3rem" h="calc(100vh - 3rem)" flexDirection="column">
      <Flex alignItems="center">
        <NavigationPane />
        {showFilter && <CardFilter />}
      </Flex>
      {props.children}
    </Flex>
  );
};

export default Frame;
