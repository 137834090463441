import React from "react";
import { Flex, Text } from "@chakra-ui/layout";

export type CreditTypes = "Comply" | "Easy" | "Hard" | "Default";

interface IProps {
  isComputable: boolean;
  creditType: CreditTypes;
  credit?: number;
  hideNumbers?: boolean;
}

const CreditIndicator: React.FC<IProps> = (props) => {
  const { isComputable, creditType, credit, hideNumbers } = props;

  const renderColorByCreditType = () => {
    if (!isComputable) return "black.800";
    switch (creditType) {
      case "Comply":
        return "green.light";
      case "Easy":
        return "yellow.light";
      case "Hard":
        return "primary.main";
      case "Default":
        return "black.800";
    }
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg={renderColorByCreditType()}
      w={{ sm: ".75rem", lg: "1rem" }}
      h={{ sm: ".75rem", lg: "1rem" }}
      display="inline-flex"
      verticalAlign="text-top"
    >
      {isComputable && !hideNumbers && (
        <Text fontSize="small" color={"white.500"}>
          {credit || 0}
        </Text>
      )}
    </Flex>
  );
};

export default CreditIndicator;
