import { Box } from "@chakra-ui/layout";
import React from "react";

interface IProps {
  color?: string;
  active?: boolean;
}

const RequirementIndicator: React.FC<IProps> = (props) => {
  const { color, active } = props;
  return (
    <Box
      borderRadius="50%"
      backgroundColor={color ? color : active ? "green.light" : "black.800"}
      w="0.75rem"
      h="0.75rem"
      display="inline-block"
      mr=".5rem"
    />
  );
};

export default RequirementIndicator;
