import React from "react";
import { Stack } from "@chakra-ui/layout";

import CreditIndicator from "./CreditIndicator";

interface IProps {
  isComputable: boolean;
  credits?: {
    comply: number;
    easy: number;
    hard: number;
  };
  hideNumbers?: boolean;
}

const CreditsIndicators: React.FC<IProps> = (props) => {
  const { isComputable, credits } = props;
  return (
    <Stack direction="row" display="inline-flex">
      <CreditIndicator
        creditType="Comply"
        isComputable={isComputable}
        credit={credits?.comply}
      />
      <CreditIndicator
        creditType="Easy"
        isComputable={isComputable}
        credit={credits?.easy}
      />
      <CreditIndicator
        creditType="Hard"
        isComputable={isComputable}
        credit={credits?.hard}
      />
    </Stack>
  );
};

export default CreditsIndicators;
