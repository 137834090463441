import { Checkbox } from "@chakra-ui/checkbox";
import { Box, VStack } from "@chakra-ui/layout";
import React from "react";

import RequirementIndicator from "../../Table/Cell/RequirementIndicator";
import { ICustomFilters } from "../FilterGroup";
import Label from "./Label";

interface IProps {
  creditFilters: ICustomFilters;
  setCreditFilters: (filters: ICustomFilters) => void;
}

const Requirements: React.FC<IProps> = (props) => {
  const { creditFilters, setCreditFilters } = props;
  return (
    <>
      <Label title="Prerequisites" />
      <Box display="inline-block" mt=".5rem">
        <VStack alignItems="flex-start">
          <Checkbox
            value="req_confirmed"
            size="sm"
            isChecked={creditFilters.requirement.comply}
            onChange={(event) =>
              setCreditFilters({
                ...creditFilters,
                requirement: {
                  ...creditFilters.requirement,
                  comply: event.target.checked,
                },
              })
            }
          >
            <RequirementIndicator color="green.light" />
            Confirmed
          </Checkbox>
          <Checkbox
            value="req_pending"
            size="sm"
            isChecked={creditFilters.requirement.pending}
            onChange={(event) =>
              setCreditFilters({
                ...creditFilters,
                requirement: {
                  ...creditFilters.requirement,
                  pending: event.target.checked,
                },
              })
            }
          >
            <RequirementIndicator />
            Pending
          </Checkbox>
        </VStack>
      </Box>
    </>
  );
};

export default Requirements;
