import React from "react";
import { useHistory } from "react-router";
import jwt from "jsonwebtoken";

import config from "../config/env";
import { useAppDispatch } from "../redux/hooks";
import { actions } from "../redux/UserSlice";

interface IProps {}

const Auth: React.FC<IProps> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const search = new URLSearchParams(history.location.search);
  const idToken = search.get("idToken");
  const recievedRefreshToken = search.get("refreshToken");

  if (idToken && recievedRefreshToken) {
    localStorage.setItem(config.TOKEN_STORAGE, idToken);
    localStorage.setItem(config.REFRESH_TOKEN_STORAGE, recievedRefreshToken);
    history.push(search.get("next") || "/");
  }

  const tokenLocalStorage = localStorage.getItem(config.TOKEN_STORAGE);
  const refreshTokenLocalStorage = localStorage.getItem(
    config.REFRESH_TOKEN_STORAGE,
  );

  if (!tokenLocalStorage || !refreshTokenLocalStorage)
    window.location.replace(
      `${config.LOGIN_REDIRECT}?nextUrl=${window.origin}&poolid=eu-west-1_AHrSRKAE7&clientid=2ld418suj7uf1bik9vamji0a50&app=dhub-leed`,
    );

  const payload = jwt.decode(tokenLocalStorage!) as jwt.JwtPayload;

  if (!payload) {
    window.location.replace(
      `${config.LOGIN_REDIRECT}?nextUrl=${window.origin}&poolid=eu-west-1_AHrSRKAE7&clientid=2ld418suj7uf1bik9vamji0a50&app=dhub-leed`,
    );
  } else {
    if (payload["exp"]! < Math.trunc(new Date().getTime() / 1000))
      window.location.replace(
        `${config.LOGIN_REDIRECT}?nextUrl=${window.origin}&poolid=eu-west-1_AHrSRKAE7&clientid=2ld418suj7uf1bik9vamji0a50&app=dhub-leed`,
      );

    dispatch(
      actions.login({
        email: payload["email"],
        name: `${payload["custom:fullName"]} ${payload["family_name"]}`,
      }),
    );
  }

  return <div>{props.children}</div>;
};

export default Auth;
