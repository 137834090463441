import React from "react";
import { Box } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import defaultPhoto from "../../../assets/images/default-photo.png";
import { IAsset } from "../../../redux/AssetSlice";
interface IProps {
  asset: IAsset;
}

function setPhoto(photo: string | undefined) {
  return typeof photo === "string" ? photo : defaultPhoto;
}

const CardDetails: React.FC<IProps> = (props) => {
  const { asset } = props;
  return (
    <Box>
      <Box color="black" fontSize="small" fontWeight="bold">
        Area:&nbsp;
        <Box as="span" color="gray.600" fontSize="small" fontWeight="normal">
          {asset.area}
        </Box>
      </Box>

      <Box color="black" fontSize="small" fontWeight="bold">
        Ownership:&nbsp;
        <Box as="span" color="gray.600" fontSize="small" fontWeight="normal">
          {asset.ownership}
        </Box>
      </Box>

      <Image
        padding="1.375rem 0rem"
        w="100%"
        h="12.25rem"
        src={setPhoto(asset.photo)}
        alt="Default photo"
        objectFit="cover"
      />
    </Box>
  );
};

export default CardDetails;
