import React from "react";
import { Box } from "@chakra-ui/layout";

import { CheckIcon } from "@chakra-ui/icons";

interface IProps {
  checked: boolean;
}

function setOutline(checked: boolean) {
  return checked ? "green.dark" : "black.700";
}
function setBG(checked: boolean) {
  return checked ? "green.transparent" : "";
}

const CustomCheckbox: React.FC<IProps> = (props) => {
  return (
    <Box
      w="100%"
      h="100%"
      borderRadius="50%"
      border="1px solid"
      borderColor={setOutline(props.checked)}
      bg={setBG(props.checked)}
    >
      {props.checked && (
        <CheckIcon
          color="green.dark"
          position="relative"
          top="15%"
          left="65%"
          transform="translate(-50%,-50%)"
          fontSize="1.2em"
        />
      )}
    </Box>
  );
};

export default CustomCheckbox;
