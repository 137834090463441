import React from "react";
import { Box } from "@chakra-ui/layout";
import { useHistory } from "react-router";
import { Button } from "@chakra-ui/react";

import CardHeader from "./CardHeader";
import CardDetails from "./CardDetails";
import CardCertifications from "./CardCertifications";
import { IAsset } from "../../../redux/AssetSlice";

interface IProps {
  key: number;
  asset: IAsset;
}

const Card: React.FC<IProps> = (props) => {
  const { asset } = props;
  const history = useHistory();

  const navigate = () => {
    history.push(`/${asset.name}`);
  };

  return (
    <Box
      pos="relative"
      h="100%"
      minW="sm"
      minH="35rem"
      bg="white.500"
      w="21rem"
      color="white"
      padding="1.188rem 1.5rem"
      transition="all .2s ease-in-out"
      cursor="pointer"
      onClick={navigate}
      _hover={{ transform: "scale(1.02)" }}
    >
      <CardHeader asset={asset} />
      <CardDetails asset={asset} />
      <CardCertifications asset={asset} />
      <Button
        position="absolute"
        bottom="0"
        marginBottom="1.5rem"
        variant="solidPrimary"
        height="fit-content"
        borderRadius="0.25rem"
        onClick={navigate}
      >
        See More
      </Button>
    </Box>
  );
};

export default Card;
