import React from "react";
import { Box } from "@chakra-ui/layout";
import { Accordion } from "@chakra-ui/react";

import { useAppSelector } from "../../../redux/hooks";
import Header from "./Row/Header";
import Row from "./Row/Row";
import RowSchema from "./Row/RowSchema";

interface IProps {}

const Table: React.FC<IProps> = (props) => {
  const credits: any[] =
    useAppSelector((state) => state.asset.filteredCredits) || [];
  return (
    <Box
      bg="white.500"
      height="100%"
      flex="1"
      padding="1.5rem"
      overflowY="auto"
    >
      <RowSchema marginBottom="0.5rem">
        <Header />
      </RowSchema>
      <Accordion allowToggle>
        {!!credits.length &&
          credits.map((credit: any, index: number) => (
            <Row key={index} credit={credit} />
          ))}
      </Accordion>
    </Box>
  );
};

export default Table;
