import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { CloseIcon } from "@chakra-ui/icons";
import { Fade } from "@chakra-ui/transition";

import CardHeader from "../../Home/Card/CardHeader";
import CardCertifications from "../../Home/Card/CardCertifications";
import FilterGroup from "./FilterGroup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { actions } from "../../../redux/AssetSlice";
import { createMarkup } from "./../../utils";

interface IProps {}

const FilterPane: React.FC<IProps> = (props) => {
  const asset = useAppSelector((state) => state.asset);
  const dispatch = useAppDispatch();

  if (!asset.selectedAsset) return <></>;

  return (
    <Box
      h="100%"
      w={"25rem"}
      mr={"1.75rem"}
      bg="white.500"
      padding="1.5rem"
      position="relative"
      overflowY="auto"
    >
      <CardHeader asset={asset.selectedAsset} />
      <CardCertifications asset={asset.selectedAsset} />
      <FilterGroup />
      <Fade in={asset.guide.isOpen} unmountOnExit>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width="100%"
          height="100%"
          bg="white.500"
          transform="translate(-50%,-50%)"
          boxShadow="md"
          p="1.5rem"
          overflowY="auto"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="primary.main" fontWeight="bold" fontSize="small">
              Guide
            </Text>
            <CloseIcon onClick={(_) => dispatch(actions.closeGuide())} />
          </Flex>

          <Text
            fontSize="small"
            mt="1rem"
            dangerouslySetInnerHTML={createMarkup(
              asset.guide.data || "No data provided",
            )}
          />
        </Box>
      </Fade>
    </Box>
  );
};

export default FilterPane;
