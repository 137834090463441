import React from "react";
import { Text } from "@chakra-ui/layout";

interface IProps {
  title: string;
}

const Label: React.FC<IProps> = (props) => {
  return (
    <Text fontWeight="bold" fontSize="small">
      {props.title}
    </Text>
  );
};

export default Label;
