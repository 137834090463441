import React from "react";
import { Route, Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { ChakraProvider } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import "focus-visible/dist/focus-visible";
import { Provider } from "react-redux";
import theme from "./config/theme";
import "./reset.css";

import Topbar from "./core/Topbar";
import Frame from "./core/Frame";
import Home from "./core/Home/Home";
import Auth from "./core/Auth";
import Axios from "./core/Axios";
import DetailedView from "./core/Detailed/DetailedView";

import store from "./redux/store";

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export const browserHistory = createBrowserHistory();

export const App: React.FC = () => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <Axios />
      <Router history={browserHistory}>
        <Auth>
          <Topbar />
          <Frame>
            <Switch>
              <Route path="/:assetId" component={() => <DetailedView />} />
              <Route path="/" component={() => <Home />} />
            </Switch>
          </Frame>
        </Auth>
      </Router>
    </ChakraProvider>
  </Provider>
);
