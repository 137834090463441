import { configureStore } from "@reduxjs/toolkit";

import assetSlice from "./AssetSlice";
import userSlice from "./UserSlice";

const store = configureStore({
  reducer: {
    asset: assetSlice,
    user: userSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch;

export default store;
