import React, { useEffect } from "react";
import { Box } from "@chakra-ui/layout";

import HorizontalScroll from "../Home/HorizontalScroll";
import { useAppDispatch } from "./../../redux/hooks";

import { actions } from "../../redux/AssetSlice";
import axios from "axios";

import config from "../../config/env";

interface IProps {}

const Home: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    axios.get(`${config.API}/loeweleed/getData`).then(({ data }) => {
      dispatch(actions.loadList(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <HorizontalScroll />
    </Box>
  );
};

export default Home;
