import React from "react";
import { HStack } from "@chakra-ui/layout";

import Card from "./Card/Card";
import { useAppSelector } from "../../redux/hooks";

interface IProps {}

const onWheel = (e: any) => {
  const scroll: any = document.getElementById("horizontal-scroll");
  const scrollPosition: any = scroll.scrollLeft;
  scroll.scrollTo({
    top: 0,
    left: scrollPosition + e.deltaY,
    behavior: "auto",
  });
};

const HorizontalScroll: React.FC<IProps> = (props) => {
  const assets = useAppSelector((state) => state.asset.filteredAssetList);
  return (
    <HStack
      pos="relative"
      spacing="2rem"
      marginBottom={{ base: "1rem", xl: "1.875rem" }}
      paddingBottom={{ base: "1rem", xl: "4.5rem" }}
      id="horizontal-scroll"
      direction="row"
      overflowX="auto"
      h="100%"
      onWheel={onWheel}
    >
      {assets.map((asset, i) => {
        return <Card key={i} asset={asset} />;
      })}
    </HStack>
  );
};

export default HorizontalScroll;
