import React from "react";
import { Box, Text } from "@chakra-ui/layout";

interface IProps {
  letter: "C" | "S" | "G" | "P";
  max: number;
  credits: number;
}
const letters: Array<String> = ["C", "S", "G", "P"];
const ratings: Array<number> = [40, 50, 60, 80];

function setPosition(letter: IProps["letter"], max: number) {
  return (ratings[letters.indexOf(letter)] / max) * 100 + "%";
}

function setColor(letter: IProps["letter"], value: number) {
  const colors: Array<any> = [
    "green.dark",
    "silver.main",
    "yellow.light",
    "black.600",
  ];
  return value >= ratings[letters.indexOf(letter)]
    ? colors[letters.indexOf(letter)]
    : "black.800";
}

function setMargin(letter: IProps["letter"]) {
  const margins: Array<string> = ["-0.5rem", "-1rem", "-1rem", "-1rem"];
  return margins[letters.indexOf(letter)];
}

const Pinpoint: React.FC<IProps> = (props) => {
  const { letter, max, credits } = props;
  return (
    <Box
      verticalAlign="top"
      position="relative"
      display="inline-block"
      marginLeft={setMargin(letter)}
      w="1rem"
      left={setPosition(letter, max)}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box h="0.5rem" w="0.08rem" bg={setColor(letter, credits)}></Box>
        <Box
          bg={setColor(letter, credits)}
          h="1rem"
          w="1rem"
          borderRadius="0.5rem"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          verticalAlign="center"
        >
          <Text color="white.500" fontSize="xs">
            {props.letter}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Pinpoint;
