import { IAssetData } from "./AssetSlice";

export type CreditType = "comply" | "easy" | "hard";

export const MIN_CERT_POINTS = 40;

const certificationGoals: any = {
  certified: 40,
  silver: 50,
  gold: 60,
  platinum: 80,
};

export const getCertificationByCredits = (complyCredits: number) => {
  if (complyCredits >= 80) return "platinum";
  else if (complyCredits >= 60) return "gold";
  else if (complyCredits >= 50) return "silver";
  else if (complyCredits >= 40) return "certified";
  else return "no certification";
};

export const convertCertificationGoalToCredit = (goal: string): number =>
  certificationGoals[goal] || 0;

export const calculateCompliedPrerequisites = (assetData: IAssetData[]) =>
  assetData.filter(
    (data) => data.type.toLowerCase().trim() === "prereq" && data.preReqComply,
  ).length;

export const calculateTotalPrerequisites = (assetData: IAssetData[]) =>
  assetData.filter((data) => data.type.toLowerCase().trim() === "prereq")
    .length;

export const calculateCredits = (assetData: IAssetData[]) =>
  assetData
    // eslint-disable-next-line array-callback-return
    .map((data) => {
      if (data.type.toLowerCase() === "credit")
        return { comply: data.comply, easy: data.easy, hard: data.hard };
    })
    .reduce(
      (acc, curr) => {
        if (acc && curr)
          acc = {
            comply: acc.comply + curr.comply,
            easy: acc.easy + curr.easy,
            hard: acc.hard + curr.hard,
          };
        return acc;
      },
      {
        comply: 0,
        easy: 0,
        hard: 0,
      },
    );
