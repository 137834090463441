import React from "react";
import { Input } from "@chakra-ui/react";
import _ from "lodash";

import Label from "./Label";
import { ICustomFilters } from "../FilterGroup";

interface IProps {
  creditFilters: ICustomFilters;
  setCreditFilters: (filters: ICustomFilters) => void;
}

const SearchBox: React.FC<IProps> = (props) => {
  const { creditFilters, setCreditFilters } = props;
  const handleChange = _.debounce((event: any) => {
    console.log(event);
    const value = event.target.value;
    setCreditFilters({ ...creditFilters, textFilter: value });
  }, 1000);
  return (
    <>
      <Label title="Text" />
      <Input
        onKeyUp={handleChange}
        placeholder="Write your search criteria"
        size="sm"
        mt="0.375rem"
      />
    </>
  );
};

export default SearchBox;
