import { AccordionIcon, Text } from "@chakra-ui/react";
import React from "react";

import CreditsIndicators from "../Cell/CreditsIndicators";
import RequirementIndicator from "../Cell/RequirementIndicator";
import CustomGridItem from "../Cell/CustomGridItem";
import { IAssetData } from "../../../../redux/AssetSlice";

interface IProps {
  credit: IAssetData;
}

const RowVisibleInfo: React.FC<IProps> = (props) => {
  const { credit } = props;
  return (
    <>
      <CustomGridItem>
        {credit.type.toLowerCase().trim() === "prereq" && (
          <RequirementIndicator active={credit.preReqComply} />
        )}
      </CustomGridItem>
      <CustomGridItem>
        <CreditsIndicators
          isComputable={credit.type.toLowerCase() === "credit"}
          credits={{
            comply: credit.comply,
            easy: credit.easy,
            hard: credit.hard,
          }}
        />
      </CustomGridItem>
      <CustomGridItem>
        <Text fontSize="small">{credit.type}</Text>
      </CustomGridItem>
      <CustomGridItem cssProps={{ colSpan: { sm: 2, lg: 3 } }}>
        <Text fontSize="small" fontWeight="bold" isTruncated>
          {credit.name}
        </Text>
      </CustomGridItem>
      <CustomGridItem cssProps={{ colSpan: { sm: 2, lg: 2 } }}>
        <Text fontSize="small" isTruncated>
          {credit.category || ""}
        </Text>
      </CustomGridItem>
      <CustomGridItem cssProps={{ colSpan: { sm: 1, lg: 2 } }}>
        <Text fontSize="small" isTruncated>
          {credit.responsible}
        </Text>
      </CustomGridItem>
      <CustomGridItem cssProps={{ justifyContent: "flex-end" }}>
        <AccordionIcon />
      </CustomGridItem>
    </>
  );
};

export default RowVisibleInfo;
