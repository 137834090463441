import React, { useState } from "react";
import { Flex, Text } from "@chakra-ui/layout";
import { Link, useHistory } from "react-router-dom";
import { Select } from "@chakra-ui/select";
import { useAppSelector } from "../redux/hooks";
import { ChevronLeftIcon } from "@chakra-ui/icons";

interface IProps {}

const NavigationPane: React.FC<IProps> = (props) => {
  const assets = useAppSelector((state) => state.asset.assetList);
  const history = useHistory();
  const [navigationSelectValue, setNavigationSelectValue] = useState(
    history.location.pathname,
  );
  const onSelectChange = (event: any) => {
    setNavigationSelectValue(event.target.value);
    history.push(event.target.value);
  };
  return (
    <Flex padding="2rem 0rem" h="6rem" flexDirection="row" alignItems="center">
      <Link to="/">
        <Text
          color="primary.main"
          fontSize="heading3"
          fontWeight="bold"
          paddingRight="1rem"
          letterSpacing="0.016rem"
        >
          {history.location.pathname !== "/" && (
            <ChevronLeftIcon verticalAlign="text-bottom" />
          )}
          Home
        </Text>
      </Link>
      <Select
        bg="white"
        display="inline-block"
        w="18rem"
        onChange={onSelectChange}
        value={navigationSelectValue}
      >
        <option value="/" disabled>
          Select project
        </option>
        {assets.length &&
          assets.map((asset, index) => (
            <option key={`${asset.name}_${index}`} value={asset.name}>
              {asset.name}
            </option>
          ))}
      </Select>
    </Flex>
  );
};

export default NavigationPane;
