import React from "react";
import { Box } from "@chakra-ui/layout";
import CustomCheckbox from "./CustomCheckbox";

interface IProps {
  value: number;
  goalReached?: boolean;
  max: number;
}
const ratings: Array<number> = [40, 50, 60, 80];

function setPosition(value: IProps["value"], max: number) {
  return (ratings[ratings.indexOf(value)] / max) * 100 + "%";
}

const Goal: React.FC<IProps> = (props) => {
  const { value, goalReached, max } = props;
  return (
    <Box
      marginTop="-1.5rem"
      verticalAlign="top"
      position="relative"
      marginLeft="-0.5rem"
      w="1rem"
      left={setPosition(value, max)}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          h="1rem"
          w="1rem"
          {...(!goalReached && { border: "0.1rem solid" })}
          borderColor="black.700"
          borderRadius="0.5rem"
          display="flex"
          justifyContent="center"
          verticalAlign="center"
        >
          {goalReached && <CustomCheckbox checked={goalReached || false} />}
        </Box>
        <Box h="0.5rem" w="0.08rem" bg="black.700"></Box>
      </Box>
    </Box>
  );
};

export default Goal;
