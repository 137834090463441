import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";

import SearchBox from "./Filters/SearchBox";
import Requirements from "./Filters/Requirements";
import Credits from "./Filters/Credits";
import Dropdown from "./Filters/Dropdown";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { actions, IAssetData } from "../../../redux/AssetSlice";

export interface ICustomFilters {
  textFilter: string;
  requirement: {
    comply: boolean;
    pending: boolean;
  };
  credit: {
    comply: boolean;
    easy: boolean;
    hard: boolean;
  };
  category: string;
  responsible: string;
}

const defaultCustomFilters: ICustomFilters = {
  textFilter: "",
  requirement: {
    comply: true,
    pending: true,
  },
  credit: {
    comply: true,
    easy: true,
    hard: true,
  },
  category: "",
  responsible: "",
};

interface IProps {}

const FilterGroup: React.FC<IProps> = (props) => {
  const [creditFilters, setCreditFilters] =
    useState<ICustomFilters>(defaultCustomFilters);
  const credits = useAppSelector((state) => state.asset.selectedAsset?.data);
  const categories = useAppSelector((state) =>
    state.asset.selectedAsset?.data
      .map((x) => x.category)
      .reduce((acc: string[], curr) => {
        if (!acc.includes(curr)) acc.push(curr);
        return acc;
      }, []),
  );

  const responsibles = useAppSelector((state) =>
    state.asset.selectedAsset?.data
      .map((x) => `${x.responsible}`)
      .reduce((acc: string[], curr) => {
        const responsibles = curr.replaceAll("\n", "").split("/");
        if (responsibles)
          responsibles.forEach(
            (responsible) =>
              !acc.includes(responsible.trim()) && acc.push(responsible.trim()),
          );
        return acc;
      }, [])
      .filter((x) => x.length > 0),
  );
  const dispatch = useAppDispatch();

  const applyFilters = () => {
    if (!credits) return [];
    let filteredCredits = [...credits];
    filteredCredits = filterBySearchBox(filteredCredits);
    filteredCredits = filterByPrereq(filteredCredits);
    filteredCredits = filterByCredit(filteredCredits);
    filteredCredits = filterByCategory(filteredCredits);
    filteredCredits = filterByResponsible(filteredCredits);
    return filteredCredits;
  };

  const filterBySearchBox = (filteredCredits: IAssetData[]) =>
    filteredCredits.filter((credit) =>
      credit.name.toLowerCase().includes(creditFilters.textFilter),
    );

  const filterByPrereq = (filteredCredits: IAssetData[]) => {
    if (!creditFilters.requirement.comply && !creditFilters.requirement.pending)
      filteredCredits = filteredCredits.filter(
        (credit) => !credit.type.toLowerCase().includes("prereq"),
      );
    else
      filteredCredits = filteredCredits.filter(
        (credit) =>
          !credit.type.toLowerCase().includes("prereq") ||
          (credit.type.toLowerCase().includes("prereq") &&
            credit.preReqComply === creditFilters.requirement.comply) ||
          credit.preReqComply === !creditFilters.requirement.pending,
      );

    return filteredCredits;
  };

  const filterByCredit = (filteredCredits: IAssetData[]) => {
    if (
      creditFilters.credit.comply &&
      creditFilters.credit.easy &&
      creditFilters.credit.hard
    )
      return filteredCredits;
    else if (
      !creditFilters.credit.comply &&
      !creditFilters.credit.easy &&
      !creditFilters.credit.hard
    )
      return filteredCredits.filter((credit) =>
        credit.type.toLowerCase().includes("prereq"),
      );

    return filteredCredits.filter(
      (credit) =>
        !credit.type.toLowerCase().includes("credit") ||
        (credit.type.toLowerCase().includes("credit") &&
          creditFilters.credit.comply &&
          credit.comply > 0) ||
        (creditFilters.credit.easy && credit.easy > 0) ||
        (creditFilters.credit.hard && credit.hard > 0),
    );
  };

  const filterByCategory = (filteredCredits: IAssetData[]) =>
    filteredCredits.filter((credit) =>
      credit.category.includes(creditFilters.category),
    );

  const filterByResponsible = (filteredCredits: IAssetData[]) =>
    filteredCredits.filter((credit) =>
      `${credit.responsible}`.includes(creditFilters.responsible),
    );

  useEffect(
    () => {
      dispatch(actions.filterCredits(applyFilters()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creditFilters],
  );

  useEffect(() => {
    credits && dispatch(actions.filterCredits(credits));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credits]);

  return (
    <Box mt="2rem">
      <Text fontWeight="bold" mb=".5rem">
        Search filters
      </Text>
      <SearchBox
        creditFilters={creditFilters}
        setCreditFilters={setCreditFilters}
      />
      <Box mt="1rem">
        <Requirements
          creditFilters={creditFilters}
          setCreditFilters={setCreditFilters}
        />
      </Box>
      <Box mt="1rem">
        <Credits
          creditFilters={creditFilters}
          setCreditFilters={setCreditFilters}
        />
      </Box>
      <Box mt="1rem">
        <Dropdown
          title={"Categories"}
          filterKey="category"
          optionsList={categories}
          creditFilters={creditFilters}
          setCreditFilters={setCreditFilters}
        />
      </Box>
      <Box mt="1rem">
        <Dropdown
          title={"Responsibles"}
          filterKey="responsible"
          optionsList={responsibles}
          creditFilters={creditFilters}
          setCreditFilters={setCreditFilters}
        />
      </Box>
      <Button
        variant="solidPrimary"
        mt="2rem"
        onClick={(_) => setCreditFilters(defaultCustomFilters)}
      >
        Reset filters
      </Button>
    </Box>
  );
};

export default FilterGroup;
