import React, { useEffect } from "react";
import { Box } from "@chakra-ui/layout";
import { useHistory, useParams } from "react-router";

import FilterPane from "./FilterPane/FilterPane";
import Table from "./Table/Table";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions } from "../../redux/AssetSlice";

interface IProps {}

const DetailedView: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const assetsList = useAppSelector((state) => state.asset.assetList);
  const history = useHistory();
  let { assetId }: { assetId: string } = useParams();

  useEffect(() => {
    if (assetId) {
      const asset = assetsList.find((asset) => asset.name === assetId);
      if (!asset) {
        history.push("/");
        return;
      }
      dispatch(actions.selectAsset(asset));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return (
    <Box w="100%" h="100%" display="flex" pb="2.375rem">
      <FilterPane />
      <Table />
    </Box>
  );
};

export default DetailedView;
