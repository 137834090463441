import { GridItem } from "@chakra-ui/react";
import React from "react";

interface IProps {
  children: any;
  cssProps?: {
    colSpan?: any;
    justifyContent?: string;
  };
}

const CustomGridItem: React.FC<IProps> = (props) => {
  const { cssProps } = props;
  return (
    <GridItem
      display="flex"
      alignItems="center"
      justifyContent={
        cssProps?.justifyContent ? cssProps.justifyContent : "flex-start"
      }
      colSpan={cssProps?.colSpan || 1}
    >
      {props.children}
    </GridItem>
  );
};

export default CustomGridItem;
