import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import React from "react";

interface IProps {}

const Header: React.FC<IProps> = (props) => {
  return (
    <>
      <GridItem colSpan={1}>
        <Text fontWeight="bold" fontSize="small" color="primary.main">
          Required
        </Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Text fontWeight="bold" fontSize="small" color="primary.main">
          Credits
        </Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Text fontWeight="bold" fontSize="small" color="primary.main">
          Type
        </Text>
      </GridItem>
      <GridItem colSpan={{ sm: 2, lg: 3 }}>
        <Text fontWeight="bold" fontSize="small" color="primary.main">
          Name
        </Text>
      </GridItem>
      <GridItem colSpan={{ sm: 2, lg: 2 }}>
        <Text fontWeight="bold" fontSize="small" color="primary.main">
          Category
        </Text>
      </GridItem>
      <GridItem colSpan={{ sm: 1, lg: 2 }}>
        <Text fontWeight="bold" fontSize="small" color="primary.main">
          Responsible
        </Text>
      </GridItem>
    </>
  );
};

export default Header;
