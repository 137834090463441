import React from "react";
import { Flex, Stack, Text } from "@chakra-ui/layout";

import arupLogo from "./../assets/images/arup_red.svg";
import { Image } from "@chakra-ui/image";

interface IProps {}

const Topbar: React.FC<IProps> = (props) => {
  return (
    <Flex
      bg="white.500"
      height="3rem"
      padding="0.875rem 3rem"
      justifyContent="space-between"
      boxShadow="base"
    >
      <Stack direction="row">
        <Text
          fontFamily="Times New Roman"
          color="primary.main"
          fontSize="heading2"
        >
          d.Hub
        </Text>
        <Text fontFamily="Times New Roman" fontSize="heading2">
          LEED ID+C certification for LOEWE
        </Text>
      </Stack>
      <Image src={arupLogo} alt="Arup logo" />
    </Flex>
  );
};

export default Topbar;
