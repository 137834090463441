import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    padding: "0.5rem 1rem",
    fontWeight: "400",
    _hover: {
      filter: "brightness(115%)",
    },
  },
  sizes: {
    md: {
      w: "fit-content",
      h: "fit-content",
      borderRadius: "md",
    },
  },
  variants: {
    solidPrimary: {
      backgroundColor: "primary.main",
      color: "white.500",
      fontSize: "small",
      fontFamily: "Arial",
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default componentOverride;
