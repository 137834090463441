import React from "react";
import { Box, Text, Flex } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import certified from "../../../assets/images/certification.png";
import gold from "../../../assets/images/gold.png";
import silver from "../../../assets/images/silver.png";
import platinum from "../../../assets/images/platinum.png";

import StackedBar from "./StackedBar";
import { IAsset } from "../../../redux/AssetSlice";

interface IProps {
  asset: IAsset;
}

const CardCertifications: React.FC<IProps> = (props) => {
  const { asset } = props;

  const selectImage = () => {
    if (asset.certified === "certified") return certified;
    else if (asset.certified === "silver") return silver;
    else if (asset.certified === "gold") return gold;
    else return platinum;
  };

  const selectColor = () => {
    if (asset.certified === "certified") return "green.dark";
    else if (asset.certified === "silver") return "silver.main";
    else if (asset.certified === "gold") return "yellow.light";
    else if (asset.certified === "platinum") return "black.500";
    else return "primary.main";
  };

  const selectText = () => {
    if (
      asset.compliedPrerequisites < asset.totalPrerequisites ||
      !asset.certified
    )
      return "Ongoing";

    return (
      (asset.certified as string).charAt(0).toUpperCase() +
      (asset.certified as string).substr(1)
    );
  };

  const prepareProps = () => {
    return {
      green: asset.creditsSummary.comply || 0,
      ...(asset.creditsSummary.easy && {
        yellow: asset.creditsSummary.easy,
      }),
      ...(asset.creditsSummary.hard && {
        red: asset.creditsSummary.hard,
      }),
    };
  };

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Box>
          <Text color="black.100" fontSize="small" fontWeight="bold">
            LEED certification status:
          </Text>
          <Text
            color={selectColor()}
            fontSize="heading1"
            marginBottom="1.063rem"
          >
            {selectText()}
          </Text>
        </Box>
        {asset.certified && (
          <Image
            h="3rem"
            w="3rem"
            src={selectImage()}
            alt="Silver certification"
          />
        )}
      </Flex>

      <Text color="black.100" fontSize="small" marginBottom="0.375rem">
        Prerequisites
      </Text>
      <StackedBar
        green={asset.compliedPrerequisites}
        max={asset.totalPrerequisites}
        pinpoints={false}
      />
      <Text
        color="black.100"
        fontSize="small"
        marginBottom="0.375rem"
        marginTop="0.875rem"
      >
        Credits
      </Text>
      <StackedBar
        goalReached={asset.creditsSummary.comply >= asset.creditsTarget}
        goal={asset.creditsTarget}
        max={
          asset.creditsSummary.comply +
          asset.creditsSummary.easy +
          asset.creditsSummary.hard
        }
        {...prepareProps()}
        pinpoints={true}
      />
    </Box>
  );
};

export default CardCertifications;
