import React from "react";
import axios from "axios";

import config from "../config/env";
import { isTokenExpired } from "../config/utils";
import { refreshToken } from "../api/users";

interface IProps {}

const Axios: React.FC<IProps> = (props) => {
  axios.interceptors.request.use(async (request) => {
    if (request.url?.includes(config.API)) {
      let idToken = localStorage.getItem(config.TOKEN_STORAGE)!;
      if (isTokenExpired(idToken)) {
        const response = await refreshToken(
          localStorage.getItem(config.REFRESH_TOKEN_STORAGE)!,
        );
        idToken = response.data.id_token;
        localStorage.setItem(config.TOKEN_STORAGE, idToken);
      }
      request.headers["Authorization"] = idToken;
    }

    return request;
  });
  return <></>;
};

export default Axios;
