import { IConfig } from ".";

const config: IConfig = {
  API: "https://api.dhub.arup.com",
  S3_IMAGES: "",
  REFRESH_TOKEN_STORAGE: "arup_login_microservice_user_refresh",
  LOGIN_REDIRECT: "https://d351b7s23jrl81.cloudfront.net/",
};

export default config;
