import { Button } from "@chakra-ui/react";
import { Box, GridItem, Text } from "@chakra-ui/layout";

import React from "react";

import RowSchema from "./RowSchema";
import { useAppDispatch } from "../../../../redux/hooks";
import { actions } from "../../../../redux/AssetSlice";
import { createMarkup } from "./../../../utils";

interface IProps {
  credit: any;
}

const RowBody: React.FC<IProps> = (props) => {
  const { credit } = props;
  const dispatch = useAppDispatch();

  return (
    <>
      <RowSchema>
        <GridItem colSpan={3} pr="1.5rem" pt="0.5rem">
          <Box>
            <Text
              fontSize="small"
              fontWeight="medium"
              letterSpacing="tight"
              color="primary.main"
            >
              Current state analysis
            </Text>
            <Text
              fontSize="small"
              mt="0.25rem"
              dangerouslySetInnerHTML={createMarkup(
                credit.status || "No data provided",
              )}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={3} pr="1.5rem" pt="0.5rem">
          <Box>
            <Text
              fontSize="small"
              fontWeight="medium"
              letterSpacing="tight"
              color="primary.main"
            >
              Actions
            </Text>
            <Text
              fontSize="small"
              mt="0.25rem"
              dangerouslySetInnerHTML={createMarkup(
                credit.actions || "No data provided",
              )}
            />
          </Box>
        </GridItem>
      </RowSchema>
      <RowSchema>
        <GridItem>
          <Button
            variant="solidPrimary"
            mt="1rem"
            onClick={(_) => dispatch(actions.openGuide(credit.requirements))}
          >
            See guide
          </Button>
        </GridItem>
      </RowSchema>
    </>
  );
};

export default RowBody;
