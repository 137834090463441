import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    control: {
      borderColor: "black.300",
      _checked: {
        bg: "black.300",
        borderColor: "black.300",
      },
    },
  },
  variants: {
    green: {
      control: {
        borderColor: "green.light",
        _checked: {
          bg: "green.light",
          borderColor: "green.light",
        },
      },
    },
    yellow: {
      control: {
        borderColor: "yellow.light",
        _checked: {
          bg: "yellow.light",
          borderColor: "yellow.light",
        },
      },
    },
    red: {
      control: {
        borderColor: "primary.main",
        _checked: {
          bg: "primary.main",
          borderColor: "primary.main",
        },
      },
    },
  },
};

export default componentOverride;
