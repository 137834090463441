import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  calculateCompliedPrerequisites,
  convertCertificationGoalToCredit,
  calculateCredits,
  calculateTotalPrerequisites,
} from "./utils";
export interface IAsset {
  name: string;
  area: string;
  location: string;
  ownership: string;
  target: string;
  certified: boolean | string;
  data: IAssetData[];
  creditsTarget: number;
  compliedPrerequisites: number;
  totalPrerequisites: number;
  creditsSummary: {
    comply: number;
    easy: number;
    hard: number;
  };
  photo: string | undefined;
}

export interface IAssetData {
  comply: number;
  easy: number;
  hard: number;
  preReqComply: boolean;
  rowType: string;
  type: string;
  total: number;
  name: string;
  requirements: string;
  status: string;
  actions: string;
  responsible: string;
  category: string;
  rowNumber: number;
}

interface ISlice {
  assetList: IAsset[];
  filteredAssetList: IAsset[];
  selectedAsset: IAsset | null;
  filteredCredits: IAssetData[] | [];
  guide: {
    isOpen: boolean;
    data: any;
  };
}

const sliceInitialState: ISlice = {
  assetList: [],
  filteredAssetList: [],
  selectedAsset: null,
  filteredCredits: [],
  guide: {
    isOpen: false,
    data: null,
  },
};

const customSlice = createSlice({
  name: "asset",
  initialState: sliceInitialState,
  reducers: {
    loadList: (state, action: PayloadAction<IAsset[]>) => {
      const convertedAssetList = action.payload.map(applyModifications);

      return {
        ...state,
        assetList: convertedAssetList,
        filteredAssetList: convertedAssetList,
      };
    },
    selectAsset: (state, action: PayloadAction<IAsset>) => {
      return { ...state, selectedAsset: action.payload };
    },
    filterAssetList: (state, action: PayloadAction<IAsset[]>) => ({
      ...state,
      filteredAssetList: action.payload,
    }),
    filterCredits: (state, action: PayloadAction<IAssetData[]>) => ({
      ...state,
      filteredCredits: action.payload,
    }),
    openGuide: (state, action: PayloadAction<any>) => ({
      ...state,
      guide: {
        isOpen: true,
        data: action.payload,
      },
    }),
    closeGuide: (state) => ({
      ...state,
      guide: {
        isOpen: false,
        data: null,
      },
    }),
  },
});

const applyModifications = (asset: IAsset) => {
  const customAsset = { ...asset };
  customAsset.data = filterRow(asset.data);
  customAsset.creditsTarget = convertCertificationGoalToCredit(
    customAsset.target,
  );
  customAsset.compliedPrerequisites = calculateCompliedPrerequisites(
    customAsset.data,
  );
  customAsset.creditsSummary = calculateCredits(customAsset.data)!;
  customAsset.totalPrerequisites = calculateTotalPrerequisites(
    customAsset.data,
  );
  return customAsset;
};

const rejectedRows = [9];

const filterRow = (assetData: IAssetData[]) =>
  assetData.filter((row) => !rejectedRows.includes(row.rowNumber));

export const actions = customSlice.actions;
export default customSlice.reducer;
