import React from "react";
import { Flex, Box, Text } from "@chakra-ui/layout";

import CustomCheckbox from "./CustomCheckbox";
import { IAsset } from "../../../redux/AssetSlice";

interface IProps {
  asset: IAsset;
}

const CardHeader: React.FC<IProps> = (props) => {
  const { asset } = props;
  return (
    <Flex justifyContent="space-between">
      <Box>
        <Text color="primary.main" fontSize="heading3" fontWeight="bold">
          {asset.name}
        </Text>
        <Text color="black.200" fontSize="small" marginBottom="1.188rem">
          {asset.location}
        </Text>
      </Box>
      <Box w="1.25rem" h="1.25rem">
        <CustomCheckbox checked={!!asset.certified} />
      </Box>
    </Flex>
  );
};

export default CardHeader;
