import { Checkbox } from "@chakra-ui/checkbox";
import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";

import { actions } from "../../redux/AssetSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CustomCheckbox from "./Card/CustomCheckbox";

interface IAssetCertificationFilter {
  reached: boolean;
  ongoing: boolean;
}

const assetCertificationFilterDefault: IAssetCertificationFilter = {
  reached: true,
  ongoing: true,
};

interface IProps {}

const CardFilter: React.FC<IProps> = (props) => {
  const assetsList = useAppSelector((state) => state.asset.assetList);
  const dispatch = useAppDispatch();

  const [assetCertificationFilter, setAssetCertificationFilter] = useState(
    assetCertificationFilterDefault,
  );

  const onChange = (event: any) => {
    setAssetCertificationFilter({
      ...assetCertificationFilter,
      [event.target.value]: event.target.checked,
    });
  };

  const filterAssetsByStatus = () => {
    if (assetCertificationFilter.ongoing && assetCertificationFilter.reached)
      return assetsList;
    else if (assetCertificationFilter.ongoing)
      return assetsList.filter((asset) => !asset.certified);
    else if (assetCertificationFilter.reached)
      return assetsList.filter((asset) => asset.certified);
    else return [];
  };

  useEffect(() => {
    dispatch(actions.filterAssetList(filterAssetsByStatus()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetCertificationFilter]);

  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="black.900"
      padding="0.5rem 1rem"
      ml="2rem"
      borderRadius="sm"
    >
      <Text fontWeight="bold" fontSize="small" mr="1.75rem">
        See objectives:
      </Text>
      <HStack alignItems="center" spacing="2rem">
        <Checkbox
          value="ongoing"
          size="sm"
          defaultChecked={true}
          onChange={onChange}
        >
          <Box
            w="1rem"
            h="1rem"
            display="inline-block"
            verticalAlign="sub"
            mr="0.25rem"
          >
            <CustomCheckbox checked={false} />
          </Box>
          Ongoing
        </Checkbox>
        <Checkbox
          value="reached"
          size="sm"
          defaultChecked={true}
          onChange={onChange}
        >
          <Box
            w="1rem"
            h="1rem"
            display="inline-block"
            verticalAlign="sub"
            mr="0.25rem"
          >
            <CustomCheckbox checked={true} />
          </Box>
          Reached
        </Checkbox>
      </HStack>
    </Flex>
  );
};

export default CardFilter;
